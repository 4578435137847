$(function(){
  $('.section-6__circle').hover(
    function(){
      $(this).find('.section-6__content').fadeToggle();
      $('.section-6__circle').css('z-index','0');
      $(this).css('z-index', '1');
      $(this).find('.section-6__popup').css('z-index','2').addClass('active-popup');
      $(this).next('.section-6__content').css('z-index','1');

    }, function(){

      $(this).find('.section-6__content').fadeToggle();
      $(this).find('.section-6__popup').removeClass('active-popup');

    }
  );
})

$(function() {
  // let media1199 = window.matchMedia("(max-width: 1199px)");
  let media991 = window.matchMedia('(max-width: 991px)');
  // let media767 = window.matchMedia("(max-width: 767px)");
  // let media575 = window.matchMedia("(max-width: 575px)");
  console.dir(media991);

  if (media991.matches) {
    $('.section-6__circle').each(function(index) {
      $(this).find('span').text(index +1);
      $(this).find('.section-6__content').prepend('<div class="section-6__legend-number">'+ (index + 1) +  '</div>');
      $(this).find('.section-6__content').appendTo('.section-6__legend');
    });

    $('.room').each(function() {
      $(this).find('.room__type').prependTo(this);
    })

  }

  // media575.addEventListener("change", function(media575) {
  //     if (media575.matches) {
  //         console.log('in 575');
  //     }else{
  //         console.log('out 575');
  //     }
  // });
  // media767.addEventListener("change", function(media767) {
  //     if (media767.matches) {
  //         console.log('in 767');
  //     }else{
  //         console.log('out 767');
  //     }
  // });
  media991.addListener( function(media991) {
    if (media991.matches) {
      $('.section-6__circle').each(function(index) {
        $(this).find('span').text(index +1);
        $(this).find('.section-6__content').prepend('<div class="section-6__legend-number">'+ (index + 1) +  '</div>');
        $(this).find('.section-6__content').appendTo('.section-6__legend');
      });

      $('.room').each(function() {
        $(this).find('.room__type').prependTo(this);
      })
    }else{
      $('.section-6__circle > span').each(function(index) {
        $(this).text('więcej');
      });

      $('.room').each(function() {
        $(this).find('.room__type').prependTo($(this).find('.room__info'));
      })
    }
  });
  // media1199.addEventListener("change", function(media1199) {
  //     if (media1199.matches) {
  //         console.log('in 1199');
  //     }else{
  //         console.log('out 1199');
  //     }
  // });

});


