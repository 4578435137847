window.$ = window.jQuery = require('jquery');

// require('jquery');
require('bootstrap');
require('simplelightbox');
require('./interactivefoto');
require('./slick.min');
require('./mobile-menu');
require('./dropdownMenu');
require('./formAnimation');
require('./condignationWidget');

$(function () {
    $('.gallery').each(function(){
        $(this).find('a').simpleLightbox();
    });
});