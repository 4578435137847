$(function() {
  $("#menu__list .has-submenu > a").on("click", function(e) {
    e.preventDefault();

    var subMenu = $(this).siblings(".submenu");
    if (subMenu.hasClass("hide-menu")) {
      $(".submenu").addClass("hide-menu");
      $("li.has-submenu > a").removeClass("active");
      $(this).addClass("active");
      subMenu.removeClass("hide-menu");
    } else {
      $(".submenu").addClass("hide-menu");
      $(this).removeClass("active");
      subMenu.addClass("hide-menu");
    }
  });
});
