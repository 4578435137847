document.addEventListener("DOMContentLoaded", function() {
    Array.from(document.querySelectorAll('[data-room-id]')).forEach( element => {
        element.addEventListener('click', function(){
            window.location = slugs[parseInt(element.dataset.roomId)];
        })
        element.addEventListener('mouseenter', function() {
            this.style.opacity = ".95";
            this.style.cursor = 'pointer';
        });
        element.addEventListener('mouseleave', function(){
            this.style.opacity = ".5";
        })
        switch(parseInt(element.dataset.roomId)) {
            case 1:
                element.setAttribute("style", "fill : rgb(218,165,32) ; opacity: .5");
                break;
            case 2:
                element.setAttribute("style", "fill : rgb(254, 237, 221); opacity: .5");
                break;
            case 3:
                element.setAttribute("style", "fill : rgb(100, 149, 237); opacity: .5");
                break;
            case 4:
                element.setAttribute("style", "fill : rgb(197, 73, 32); opacity: .5");
                break;
            case 5:
                element.setAttribute("style", "fill : rgb(169, 169, 169); opacity: .5");
                break;
            case 7:
                element.setAttribute("style", "fill : rgb(167, 86, 163); opacity: .5");
                break;
            default:
        }
    });

    Array.from(document.querySelectorAll('[data-floor-id]')).forEach( element => {
        element.setAttribute("style", "fill : #4a2e47; opacity : .5");
        element.addEventListener('mouseenter', function() {
            this.style.opacity = ".95";
            this.style.cursor = 'pointer';
        });
        element.addEventListener('mouseleave', function(){
            this.style.opacity = ".5";
        })
        element.addEventListener('click', function(){
            this.closest('.room-widget__element').classList.add('animated', 'fadeOutLeft', 'hide');
            document.getElementById(`room-widget__${this.dataset.floorId}`).classList.remove('fadeOutRight', 'hide');
            document.getElementById(`room-widget__${this.dataset.floorId}`).classList.add('animated', 'fadeInRight', 'show');
            document.getElementById(`room-widget__${this.dataset.floorId}`).classList.remove('hide-init');
        });
    });

    Array.from(document.getElementsByClassName('room-widget__back')).forEach( element => {
        element.addEventListener('click', function(){
            this.closest('.room-widget__element').classList.add('animated', 'fadeOutRight', 'hide');
            document.getElementById("room-widget__main").classList.remove('fadeOutLeft', 'hide');
            document.getElementById("room-widget__main").classList.add('animated','fadeInLeft', 'show');
        });
    });
});